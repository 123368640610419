import { MapInfo } from "../data/map-info";
import { TopSCDataInfo } from "../data/top-sc";
import { SearchQuery } from "../helpers/query-builder";

import { BaseService } from "./api-client/base-service";
import { BearerTokenApiClient } from "./api-client/bearer-token-api-client";

export class QueryService extends BaseService {
  public constructor(apiClient: BearerTokenApiClient) {
    super(apiClient);
  }

  getQuery(query: string): Promise<MapInfo> {
    return this.apiClient.post("/v1/queries/custom", { query: query });
  }

  getSearchQuery(query: SearchQuery): Promise<MapInfo> {
    return this.apiClient.post("/v1/queries/supercentenarians/search", {
      query: query,
    });
  }

  getTopSuperCentenaries(): Promise<TopSCDataInfo> {
    return this.apiClient.get("/v1/queries/top-sc");
  }
}
export const queryService = new QueryService(new BearerTokenApiClient());
