import { Box, Flex } from '@chakra-ui/react';

import { withAuthProtection } from '../../services/protect-route-element';
import { MetricCard } from './components/MetricCard';
import { queryService } from '../../services/query-service';

import { useEffect, useState } from 'react';

const Page = () => {
  const [supercentenariansCount, setSupercentenariansCount] = useState<
    number | undefined
  >();
  const [supercentenariansAliveCount, setSupercentenariansAliveCount] =
    useState<number>();
  const [supercentenariansValidatedCount, setSupercentenariansValidatedCount] =
    useState<number>();
  const [
    supercentenariansNotValidatedCount,
    setSupercentenariansNotValidatedCount,
  ] = useState<number>();

  const [manSupercentenariansCount, setManSupercentenariansCount] = useState<
    number | undefined
  >();
  const [manSupercentenariansAliveCount, setManSupercentenariansAliveCount] =
    useState<number>();
  const [
    manSupercentenariansValidatedCount,
    setManSupercentenariansValidatedCount,
  ] = useState<number>();
  const [
    manSupercentenariansNotValidatedCount,
    setManSupercentenariansNotValidatedCount,
  ] = useState<number>();

  const [womanSupercentenariansCount, setWomanSupercentenariansCount] =
    useState<number | undefined>();
  const [
    womanSupercentenariansAliveCount,
    setWomanSupercentenariansAliveCount,
  ] = useState<number>();
  const [
    womanSupercentenariansValidatedCount,
    setWomanSupercentenariansValidatedCount,
  ] = useState<number>();
  const [
    womanSupercentenariansNotValidatedCount,
    setWomanSupercentenariansNotValidatedCount,
  ] = useState<number>();

  useEffect(() => {
    const fetchQueries = async () => {
      await fetchSupercentenarians();
      await fetchSupercentenariansAlive();
      await fetchSupercentenariansValidated();
      await fetchSupercentenariansNotValidated();

      await fetchGenderSupercentenarians(true);
      await fetchGenderSupercentenariansAlive(true);
      await fetchGenderSupercentenariansValidated(true);
      await fetchGenderSupercentenariansNotValidated(true);

      await fetchGenderSupercentenarians(false);
      await fetchGenderSupercentenariansAlive(false);
      await fetchGenderSupercentenariansValidated(false);
      await fetchGenderSupercentenariansNotValidated(false);
    };
    fetchQueries();
  }, []);

  const fetchSupercentenarians = async () => {
    const sc = await queryService.getQuery(JSON.parse('{}'));
    setSupercentenariansCount(sc.count);
  };

  const fetchSupercentenariansAlive = async () => {
    const sc = await queryService.getQuery(
      JSON.parse(`{"acf.personal_information.is_dead": {"$eq": false}}`)
    );
    setSupercentenariansAliveCount(sc.count);
  };

  const fetchSupercentenariansValidated = async () => {
    const sc = await queryService.getQuery(
      JSON.parse(`{"acf.sc_validated": {"$eq": true}}`)
    );
    setSupercentenariansValidatedCount(sc.count);
  };

  const fetchSupercentenariansNotValidated = async () => {
    const sc = await queryService.getQuery(
      JSON.parse(`{"acf.sc_validated": {"$eq": false}}`)
    );
    setSupercentenariansNotValidatedCount(sc.count);
  };

  const fetchGenderSupercentenarians = async (isMale: boolean) => {
    const sc = await queryService.getQuery(
      JSON.parse(
        `{"acf.personal_information.sex.name": {"$eq": "${
          isMale ? 'Male' : 'Female'
        }"}}`
      )
    );
    if (isMale) {
      setManSupercentenariansCount(sc.count);
    } else {
      setWomanSupercentenariansCount(sc.count);
    }
  };

  const fetchGenderSupercentenariansAlive = async (isMale: boolean) => {
    const sc = await queryService.getQuery(
      JSON.parse(
        `{"acf.personal_information.is_dead": {"$eq": false},"acf.personal_information.sex.name": {"$eq": "${
          isMale ? 'Male' : 'Female'
        }"}}`
      )
    );
    if (isMale) {
      setManSupercentenariansAliveCount(sc.count);
    } else {
      setWomanSupercentenariansAliveCount(sc.count);
    }
  };

  const fetchGenderSupercentenariansValidated = async (isMale: boolean) => {
    const sc = await queryService.getQuery(
      JSON.parse(
        `{"acf.sc_validated": {"$eq": true}, "acf.personal_information.sex.name": {"$eq": "${
          isMale ? 'Male' : 'Female'
        }"}}`
      )
    );
    if (isMale) {
      setManSupercentenariansValidatedCount(sc.count);
    } else {
      setWomanSupercentenariansValidatedCount(sc.count);
    }
  };

  const fetchGenderSupercentenariansNotValidated = async (isMale: boolean) => {
    const sc = await queryService.getQuery(
      JSON.parse(
        `{"acf.sc_validated": {"$eq": false}, "acf.personal_information.sex.name": {"$eq": "${
          isMale ? 'Male' : 'Female'
        }"}}`
      )
    );
    if (isMale) {
      setManSupercentenariansNotValidatedCount(sc.count);
    } else {
      setWomanSupercentenariansNotValidatedCount(sc.count);
    }
  };

  const getAllDashboard = () => {
    return (
      <Flex gap={2} fontSize={'2xl'} direction={'column'} align={'center'}>
        <strong>All</strong>
        <Flex
          w={350}
          h={300}
          direction={'column'}
          bg={'linear-gradient(180deg, #e9fbf4 0%, #c2ecfe 100%)'}
          borderRadius={50}
        >
          <Flex h={'100%'} direction={'row'} justify={'space-evenly'}>
            <Box
              borderBottom={'1px'}
              borderColor={'#003058'}
              borderRight={'1px'}
              width={'100%'}
            >
              <MetricCard
                numericValue={supercentenariansCount}
                title="Supercentenarians"
              ></MetricCard>
            </Box>
            <Box borderBottom={'1px'} borderColor={'#003058'} width={'100%'}>
              <MetricCard
                numericValue={supercentenariansAliveCount}
                title="Alive"
              ></MetricCard>
            </Box>
          </Flex>
          <Flex h={'100%'} direction={'row'} justify={'space-evenly'}>
            <Box borderRight={'1px'} borderColor={'#003058'} width={'100%'}>
              <MetricCard
                numericValue={supercentenariansValidatedCount}
                title="Validated"
              ></MetricCard>
            </Box>
            <Box borderColor={'#003058'} width={'100%'}>
              {' '}
              <MetricCard
                numericValue={supercentenariansNotValidatedCount}
                title="Not Validated"
              ></MetricCard>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    );
  };
  const getManDashboard = () => {
    return (
      <Flex gap={2} fontSize={'2xl'} direction={'column'} align={'center'}>
        <strong>Men</strong>
        <Flex
          w={350}
          h={300}
          direction={'column'}
          bg={'linear-gradient(180deg, #e9fbf4 0%, #c2ecfe 100%)'}
          borderRadius={50}
        >
          <Flex h={'100%'} direction={'row'} justify={'space-evenly'}>
            <Box
              borderBottom={'1px'}
              borderColor={'#003058'}
              borderRight={'1px'}
              width={'100%'}
            >
              <MetricCard
                numericValue={manSupercentenariansCount}
                title="Supercentenarians"
              ></MetricCard>
            </Box>
            <Box borderBottom={'1px'} borderColor={'#003058'} width={'100%'}>
              <MetricCard
                numericValue={manSupercentenariansAliveCount}
                title="Alive"
              ></MetricCard>
            </Box>
          </Flex>
          <Flex h={'100%'} direction={'row'} justify={'space-evenly'}>
            <Box borderRight={'1px'} borderColor={'#003058'} width={'100%'}>
              <MetricCard
                numericValue={manSupercentenariansValidatedCount}
                title="Validated"
              ></MetricCard>
            </Box>
            <Box borderColor={'#003058'} width={'100%'}>
              {' '}
              <MetricCard
                numericValue={manSupercentenariansNotValidatedCount}
                title="Not Validated"
              ></MetricCard>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    );
  };
  const getWomanDashboard = () => {
    return (
      <Flex gap={2} fontSize={'2xl'} direction={'column'} align={'center'}>
        <strong>Woman</strong>
        <Flex
          w={350}
          h={300}
          direction={'column'}
          bg={'linear-gradient(180deg, #e9fbf4 0%, #c2ecfe 100%)'}
          borderRadius={50}
        >
          <Flex h={'100%'} direction={'row'} justify={'space-evenly'}>
            <Box
              borderBottom={'1px'}
              borderColor={'#003058'}
              borderRight={'1px'}
              width={'100%'}
            >
              <MetricCard
                numericValue={womanSupercentenariansCount}
                title="Supercentenarians"
              ></MetricCard>
            </Box>
            <Box borderBottom={'1px'} borderColor={'#003058'} width={'100%'}>
              <MetricCard
                numericValue={womanSupercentenariansAliveCount}
                title="Alive"
              ></MetricCard>
            </Box>
          </Flex>
          <Flex h={'100%'} direction={'row'} justify={'space-evenly'}>
            <Box borderRight={'1px'} borderColor={'#003058'} width={'100%'}>
              <MetricCard
                numericValue={womanSupercentenariansValidatedCount}
                title="Validated"
              ></MetricCard>
            </Box>
            <Box borderColor={'#003058'} width={'100%'}>
              {' '}
              <MetricCard
                numericValue={womanSupercentenariansNotValidatedCount}
                title="Not Validated"
              ></MetricCard>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    );
  };
  return (
    <Flex
      className="HomePageWrapper"
      justify={'center'}
      align={'center'}
      height={'100%'}
      direction={'column'}
      gap={50}
    >
      {getAllDashboard()}
      <Flex gap={200}>
        {getWomanDashboard()}
        {getManDashboard()}
      </Flex>
    </Flex>
  );
};

export const HomePage = withAuthProtection(Page);
