import { withAuthProtection } from '../../services/protect-route-element';
import { useSearchParams } from 'react-router-dom';
import { humanService } from '../../services/humans-service';
import { useEffect, useState } from 'react';
import { HumanInfo } from '../../data/humans-data';
import { Button, Flex, HStack, StackDivider } from '@chakra-ui/react';
import { HumanDetailCard } from './components/Humans/HumanDetailCard/HumanDetailCard';
import { EditorialDetailCard } from './components/Editorial/EditorialDetailCard/EditorialDetailCard';
import { TaxonomyDetailCard } from './components/Taxonomy/TaxonomyDetailCard/TaxonomyDetailCard';
import { PropertyToEdit } from '../../data/map-info';
import { NewTaxonomyCard } from './components/Taxonomy/NewTaxonomyCard/NewTaxonomyCard';
import { SupercentenarianTaxonomy } from '../../data/taxonomy-data';

const Page = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const [humanData, setHumanData] = useState<HumanInfo>();
  const [taxonomyData, setTaxonomyData] = useState<
    SupercentenarianTaxonomy | undefined
  >(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [propertiesToEdit, setPropertiesToEdit] = useState<PropertyToEdit[]>();

  const fetchHumanData = async () => {
    try {
      setLoading(true);
      const humanDetailResponse = await humanService.getHumanDetail(id ?? '');
      setHumanData(humanDetailResponse);

      const taxonomyResponse = await humanService.getHumanWithTaxonomy(
        humanDetailResponse?.content.slug
      );

      if (taxonomyResponse.content?.taxonomy) {
        setTaxonomyData(taxonomyResponse.content.taxonomy);
      } else {
        setTaxonomyData(undefined);
      }
    } catch (error) {
      console.error('Error fetching human data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHumanData();
  }, [id]);

  const handleSave = async () => {
    if (!id || !propertiesToEdit) {
      return;
    }
    try {
      setLoading(true);
      await humanService.editHumanDetail(id, propertiesToEdit);
    } catch (error: any) {
    } finally {
      await fetchHumanData();
      setPropertiesToEdit(undefined);
      setLoading(false);
    }
  };

  return (
    <Flex
      className="DetailsPageWrapper"
      width={'100%'}
      height={'100%'}
      direction={'column'}
      overflow={'auto'}
      bgColor={'#ffffff'}
      paddingY={'1rem'}
    >
      <Flex
        className="SaveButtonWrapper"
        width={'100%'}
        paddingRight={'1rem'}
        justify={'end'}
      >
        <Button
          colorScheme="teal"
          isDisabled={!propertiesToEdit}
          isLoading={loading}
          onClick={() => {
            handleSave();
          }}
        >
          Save
        </Button>
      </Flex>
      <HStack spacing={1} justify={'space-between'} h={'100%'}>
        <HumanDetailCard
          title="Human"
          source="LongeviQuest"
          content={humanData?.content}
          onContentChange={propertyToEdit =>
            setPropertiesToEdit([...(propertiesToEdit ?? []), propertyToEdit])
          }
          loading={loading}
        />
        {/* <TaxonomyDetailCard
          content={humanData?.content}
          title="Taxonomy"
          source="LQ"
          onContentChange={propertyToEdit =>
            setPropertiesToEdit([...(propertiesToEdit ?? []), propertyToEdit])
          }
          loading={loading}
        /> */}
        <NewTaxonomyCard
          title="Taxonomy"
          taxonomy={taxonomyData}
          loading={loading}
        />
        <EditorialDetailCard
          title="Editorial"
          source="WordPress"
          content={humanData?.content}
          onContentChange={propertyToEdit =>
            setPropertiesToEdit([...(propertiesToEdit ?? []), propertyToEdit])
          }
          loading={loading}
        />
      </HStack>
    </Flex>
  );
};

export const DetailPage = withAuthProtection(Page);
