import { Flex, Text } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import { FunctionComponent } from "react";

export interface MetricCardProp {
  title: string;
  numericValue?: number;
}
export const MetricCard: FunctionComponent<MetricCardProp> = (props) => {
  return (
    <Flex
      h={"100%"}
      direction={"column"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Text fontSize={"3xl"} fontWeight={600}>
        {props.numericValue ?? <Spinner />}
      </Text>
      <Text fontSize={"18"} fontWeight={600}>
        {props.title}
      </Text>
    </Flex>
  );
};
