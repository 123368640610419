export interface Field {
  name: string;
  label: string;
  type: 'text' | 'date' | 'select';
  canHideIfDataIsNull?: boolean;
  dependsOnLivingStatus?: boolean;
}

export const allowedHumanFields: Field[] = [
  { name: 'acf.sc_validated', label: 'Validation Status', type: 'select' },
  {
    name: 'acf.personal_information.nationality.name',
    label: 'Nationality',
    type: 'text',
  },
  { name: 'acf.personal_information.name', label: 'Name', type: 'text' },
  {
    name: 'acf.personal_information.lastname',
    label: 'Lastname',
    type: 'text',
  },
  { name: 'acf.personal_information.birth', label: 'Birth Date', type: 'date' },
  {
    name: 'acf.personal_information.birth_place.country.name',
    label: 'Country of Birth',
    type: 'select',
  },
  {
    name: 'acf.personal_information.birth_place.city',
    label: 'City of Birth',
    type: 'text',
  },
  {
    name: 'acf.personal_information.residence.country.name',
    label: 'Country of Residence',
    type: 'select',
  },
  {
    name: 'acf.personal_information.residence.city',
    label: 'City of Residence',
    type: 'text',
  },
  {
    name: 'acf.personal_information.sex.name',
    label: 'Gender',
    type: 'select',
  },
  {
    name: 'acf.personal_information.is_dead',
    label: 'Living Status',
    type: 'select',
  },
  {
    name: 'acf.personal_information.date_of_death',
    label: 'Died on',
    type: 'date',
    dependsOnLivingStatus: true,
  },
  {
    name: 'acf.personal_information.death_place.country.name',
    label: 'Country of Death',
    type: 'select',
    dependsOnLivingStatus: true,
  },
  {
    name: 'acf.personal_information.death_place.city',
    label: 'City of Death',
    type: 'text',
    dependsOnLivingStatus: true,
  },
];

export const allowedTaxonomyFields: Field[] = [
  { name: 'taxonomy_MilitaryService', label: 'Military Service', type: 'text' },
  { name: 'taxonomy_Marriage', label: 'Marriage', type: 'text' },
  { name: 'taxonomy_Issue', label: 'Issues', type: 'text' },
  { name: 'taxonomy_Family', label: 'Family', type: 'text' },
  { name: 'taxonomy_HabitVices', label: 'Habits or Vices', type: 'text' },
  {
    name: 'taxonomy_CauseOfDeath',
    label: 'Cause of Death',
    type: 'text',
    dependsOnLivingStatus: true,
  },
];
