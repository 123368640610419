import { Route, Routes } from "react-router-dom";
import { EmptyLayout } from "./layouts/EmptyLayout/EmptyLayout";
import { MainLayout } from "./layouts/MainLayout/MainLayout";
import { HomePage } from "./pages/HomePage/HomePage";
import { LoginCallbackPage } from "./pages/LoginCallBackPage/LoginCallbackPage";
import { LoginPage } from "./pages/LoginPage/LoginPage";
import PageNotFoundPage from "./pages/PageNotFound/PageNotFound";
import { SearchPage } from "./pages/SearchPage/SearchPage";
import { DetailPage } from "./pages/DetailPage/DetailPage";
import { RecentValidationsPage } from "./pages/RecentValidations/RecentValidationsPage";
import { CurrentBirthdaysPage } from "./pages/CurrentBirthdays/CurrentBirthdaysPage";
import { RecentDeathsPage } from "./pages/RecentDeaths/RecentDeathsPage";
import { TitleHoldersPage } from "./pages/TitleHolders/TitleHoldersPage";
import { TopDirectoryPage } from "./pages/TopDirectoryPage/TopDirectoryPage";
//trigger github action
export const Kernel = () => {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route path="home" element={<HomePage />} />
        <Route path="/recent-validations" element={<RecentValidationsPage />} />
        <Route path="/current-birthdays" element={<CurrentBirthdaysPage />} />
        <Route path="/recent-deaths" element={<RecentDeathsPage />} />
        <Route path="/title-holders" element={<TitleHoldersPage />} />
        <Route path="/top-directory" element={<TopDirectoryPage />} />
        <Route path="search" element={<SearchPage />} />
        <Route path="detail" element={<DetailPage />} />
        <Route index element={<HomePage />} />
      </Route>
      <Route path="/" element={<EmptyLayout />}>
        <Route path="login" element={<LoginPage />} />
        <Route path="login-callback" element={<LoginCallbackPage />} />
      </Route>
      <Route path="*" element={<PageNotFoundPage />} />
    </Routes>
  );
};
