import { HumanInfo } from '../data/humans-data';
import { PropertyToEdit } from '../data/map-info';
import { BaseService } from './api-client/base-service';
import { BearerTokenApiClient } from './api-client/bearer-token-api-client';

export class HumanService extends BaseService {
  public constructor(apiClient: BearerTokenApiClient) {
    super(apiClient);
  }

  getHumanDetail(id: string): Promise<HumanInfo> {
    return this.apiClient.get(`/v1/humans/${id}`);
  }

  getHumanWithTaxonomy(slug: string): Promise<any> {
    return this.apiClient.get(`/v1/humans/taxonomy/${slug}`);
  }

  editHumanDetail(
    id: string,
    propertiesToEdit: PropertyToEdit[]
  ): Promise<HumanInfo> {
    return this.apiClient.put(`/v1/humans/${id}`, propertiesToEdit);
  }
}
export const humanService = new HumanService(new BearerTokenApiClient());
