import React, { useState } from "react";
import "./LoginPage.scss";
import { authentication } from "../../services/authentication";
import data from "../../config/config.json";
import { Input } from "@chakra-ui/input";

export const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const onSignIn = async (e?: React.FormEvent<HTMLFormElement>) => {
    if (e !== undefined) {
      e.preventDefault();
    }
    if (email !== "" && password !== "") {
      try {
        await authentication.loginWithUserAndPassword(
          email as string,
          password
        );
      } catch (e: any) {
        setError(e.description);
      }
    } else {
      setError("Email or Password are empty");
    }
  };

  return (
    <div className="LoginPage">
      <form className="RepLoginForm" onSubmit={(e) => onSignIn(e)}>
        <img alt="rep-Login-form-icon" src={data.icon} />
        <Input
          placeholder={"Email"}
          defaultValue={email}
          className={`RepLoginFormInput`}
          type="text"
          onChange={(email) => setEmail(email.currentTarget.value)}
        />
        <Input
          placeholder={"Password"}
          defaultValue={password}
          className={`RepLoginFormInput`}
          type="password"
          onChange={(password) => setPassword(password.currentTarget.value)}
        />
        <button onClick={() => onSignIn()}>Sign In</button>
        {error && (
          <div className="RepLoginErrorContainer">
            <div className="RepLoginErrorMessage">{error}</div>
          </div>
        )}
      </form>
    </div>
  );
};
