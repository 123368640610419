import {
  Avatar,
  Box,
  Flex,
  HStack,
  Image,
  Stack,
  StackDivider,
  Text,
} from '@chakra-ui/react';
import {
  FiBookmark,
  FiCalendar,
  FiCheck,
  FiHome,
  FiSearch,
} from 'react-icons/fi';
import { SidebarButton } from './SideBarButton';
import { useNavigate } from 'react-router-dom';
import { authentication } from '../../../services/authentication';
import lgIcon from '../../../assets/lq-icon.svg';
import { UserMenu } from './Menu';
export const SideBar = () => {
  const navigate = useNavigate();
  const user = authentication.getUser();
  return (
    <Flex
      as="section"
      minH="100vh"
      background={'linear-gradient(180deg, #e9fbf4 0%, #c2ecfe 100%)'}
    >
      <Stack
        flex="1"
        maxW={{ base: 'full', sm: 'xs' }}
        py={{ base: '6', sm: '8' }}
        px={{ base: '4', sm: '6' }}
        bg="bg.accent.default"
        color="fg.accent.default"
        borderRightWidth="1px"
        justifyContent="space-between"
      >
        <Stack spacing="8">
          <Flex
            className="ToolBarAppIconSection"
            alignItems={'center'}
            bg={'#e9fbf4'}
            gap={1}
            justifyContent={'left'}
            width={'15rem'}
          >
            <Image width={'1.5rem'} alt="lqIcon" src={lgIcon} />
            <p style={{ color: '#272349' }}>
              <strong>LQ Human Data Platform</strong>
            </p>
          </Flex>
          <Stack spacing="1">
            <SidebarButton
              onClick={() => {
                navigate('/');
              }}
              leftIcon={<FiHome />}
            >
              Home
            </SidebarButton>
            <SidebarButton
              onClick={() => {
                navigate('/top-directory');
              }}
              leftIcon={<FiBookmark />}
            >
              Top Supercentarians
            </SidebarButton>
            <SidebarButton
              onClick={() => {
                navigate('/recent-validations');
              }}
              leftIcon={<FiCheck />}
            >
              Validations
            </SidebarButton>
            <SidebarButton
              onClick={() => {
                navigate('/current-birthdays');
              }}
              leftIcon={<FiCalendar />}
            >
              Birthdays
            </SidebarButton>
            <SidebarButton
              onClick={() => {
                navigate('/recent-deaths');
              }}
              leftIcon={<FiBookmark />}
            >
              Recent Deaths
            </SidebarButton>
            <SidebarButton
              onClick={() => {
                navigate('/title-holders');
              }}
              leftIcon={<FiBookmark />}
            >
              Country Title Holders
            </SidebarButton>
            <SidebarButton
              onClick={() => {
                navigate('/search');
              }}
              leftIcon={<FiSearch />}
            >
              Search
            </SidebarButton>
          </Stack>
        </Stack>
        <Stack
          spacing="4"
          divider={<StackDivider borderColor="bg.accent.subtle" />}
        >
          <Box />

          <HStack spacing="3" justify="space-between">
            <HStack spacing="3">
              <Avatar boxSize="10" src={user?.picture} />
              <Box>
                <Text textStyle="sm" fontWeight="medium">
                  {user?.nickname}
                </Text>
                <Text textStyle="sm" color="fg.accent.muted">
                  {user?.email}
                </Text>
              </Box>
            </HStack>
            <UserMenu />
          </HStack>
        </Stack>
      </Stack>
    </Flex>
  );
};
