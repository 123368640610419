import { FunctionComponent } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { MapInfo } from '../../../data/map-info';
import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  IconButton,
  Button,
} from '@chakra-ui/react';
import './SearchResultList.scss';
import { CountryNameToCountryCode } from '../../Countries';
import { FiDownload } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { TablePlaceholder } from '../TablePlaceholder/TablePlaceholder';
import { FlagComponent } from '../FlagComponent/FlagComponent';

export const SearchResultList: FunctionComponent<MapInfo> = props => {
  const navigate = useNavigate();

  const fixAge = (milliseconds: number) => {
    return Math.floor(milliseconds / 31556952000);
  };

  const getQueryListResultsTable = () => {
    return (
      <TableContainer className="SearchResultList">
        <Table variant="striped" colorScheme={'gray'}>
          <Thead bgColor={'#003058'}>
            <Tr>
              <Th textColor={'#ffffff'}>Name</Th>
              <Th textColor={'#ffffff'}>Born</Th>
              <Th textColor={'#ffffff'}>Died</Th>
              <Th textColor={'#ffffff'}>Age</Th>
              <Th textColor={'#ffffff'}>Gender</Th>
              <Th textColor={'#ffffff'}>Birthplace</Th>
              <Th textColor={'#ffffff'}>Deathplace</Th>
              <Th textColor={'#ffffff'}>Status</Th>
              {props.showDetails && <Th textColor={'#ffffff'}>Actions</Th>}
            </Tr>
          </Thead>
          <Tbody>
            {!props.isLoaded ? <TablePlaceholder /> : <></>}
            {props.content.map((value, index) => {
              return (
                <Tr key={index}>
                  <Td>
                    {value.acf.personal_information.name +
                      ' ' +
                      value.acf.personal_information.lastname}
                  </Td>
                  <Td>
                    {new Date(
                      value.acf.personal_information.birth
                    ).toLocaleDateString('en-GB', {
                      timeZone: 'UTC',
                    })}
                  </Td>
                  <Td>
                    {!value.acf.personal_information.is_dead
                      ? 'Living'
                      : value.acf.personal_information.date_of_death
                      ? new Date(
                          value.acf.personal_information.date_of_death
                        ).toLocaleDateString('en-GB', {
                          timeZone: 'UTC',
                        })
                      : 'N/A'}
                  </Td>
                  <Td>{fixAge(value.ageInMilliseconds).toString()}</Td>
                  <Td>{value.acf.personal_information.sex.name}</Td>
                  <Td>
                    {value.acf.personal_information.birth_place.country.name !==
                    '' ? (
                      <>
                        <FlagComponent
                          countryCode={CountryNameToCountryCode(
                            value.acf.personal_information.birth_place.country
                              .name
                          )}
                        />
                        {' ' +
                          value.acf.personal_information.birth_place.country
                            .name}
                      </>
                    ) : (
                      'N/A'
                    )}
                  </Td>
                  <Td>
                    {!value.acf.personal_information.is_dead ? (
                      'Living'
                    ) : value.acf.personal_information.death_place.country
                        .name ? (
                      <>
                        <FlagComponent
                          countryCode={CountryNameToCountryCode(
                            value.acf.personal_information.death_place.country
                              .name
                          )}
                        />
                        {' ' +
                          value.acf.personal_information.death_place.country
                            .name}
                      </>
                    ) : (
                      'N/A'
                    )}
                  </Td>
                  <Td>
                    {value.acf.sc_validated ? 'Validated' : 'Not Validated'}
                  </Td>
                  {props.showDetails && (
                    <Td>
                      <Button
                        colorScheme={'teal'}
                        onClick={() => {
                          navigate(`/detail?id=` + value._id);
                        }}
                      >
                        Details
                      </Button>
                    </Td>
                  )}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    );
  };

  const downloadData = () => {
    window.open(props.url + '?csv=1', '_blank');
  };

  if (props.count === 0 && props.isLoaded) {
    return <span>There is no data matching that criteria</span>;
  }

  return (
    <Flex
      className="QueryResultListWrapper"
      width={'100%'}
      direction={'column'}
      overflowY={'auto'}
      gap={1}
    >
      <Flex
        width={'100%'}
        className="QueryResultListTitle"
        direction={'row'}
        gap={1}
        alignItems={'center'}
        justifyContent={'end'}
        marginEnd={1}
      >
        <Text
          className="QueryEditorTitle"
          textColor={'#003058'}
          display={'inline'}
          fontSize={'1rem'}
          fontWeight={'700'}
          lineHeight={'1rem'}
          padding={'0.5rem'}
        >
          Total: <strong>{props.count}</strong>
        </Text>
        {props.url && (
          <IconButton
            icon={<FiDownload />}
            onClick={downloadData}
            aria-label={''}
          />
        )}
      </Flex>
      <Box
        className="TableWrapper"
        height={props.panelInfo ?? 'calc(100vh - 200px)'}
        width={'100%'}
        overflowY={'scroll'}
      >
        {getQueryListResultsTable()}
      </Box>
      <Flex gap={'2rem'} width={'100%'}></Flex>
    </Flex>
  );
};
