import {
  Menu,
  IconButton,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { authentication } from "../../../services/authentication";
import { FiMoreVertical } from "react-icons/fi";
export const UserMenu = () => {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        variant="tertiary.accent"
        icon={<FiMoreVertical />}
        aria-label="Open Menu"
      />
      <MenuList>
        <MenuItem
          onClick={() => {
            if (authentication.isAuthenticated()) {
              authentication.logout();
            }
          }}
        >
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
