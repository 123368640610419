export const getPropertyValue = (obj: any, propertyPath: string) => {
  const properties = propertyPath.split('.');
  let value = obj;

  for (const prop of properties) {
    value = value[prop];
    if (value === undefined) break;
  }
  return value;
};

export const setNestedProperty = <T>(obj: T, path: string, value: any) => {
  const properties = path.split('.');
  let current: any = obj;

  for (let i = 0; i < properties.length - 1; i++) {
    const property = properties[i];

    if (!current[property]) {
      current[property] = {};
    }
    current = current[property];
  }

  current[properties[properties.length - 1]] = value;
};
