import {
  Box,
  Flex,
  Text,
  Image,
  Card,
  CardBody,
  Stack,
  StackDivider,
  Skeleton,
} from '@chakra-ui/react';
import { FunctionComponent, useEffect, useState } from 'react';
import { PropertyToEdit, Supercentenarian } from '../../../../../data/map-info';
import _ from 'lodash';
import { EditorialDetailsContent } from '../EditorialDetailsContent/EditorialDetailsContent';
import { setNestedProperty } from '../../../../../helpers/data-helper';

interface DetailCardProps {
  title: string;
  source: string;
  content?: Supercentenarian;
  loading?: boolean;
  onContentChange: (propertyToEdit: PropertyToEdit) => void;
}

export const EditorialDetailCard: FunctionComponent<
  DetailCardProps
> = props => {
  const [content, setContent] = useState<Supercentenarian>();
  useEffect(() => {
    setContent(props.content);
  }, [props]);

  const getEditorialDetails = () => {
    if (!content) {
      return;
    }

    const propertyName = 'acf.biography';

    return (
      <Card>
        <CardBody>
          <Stack divider={<StackDivider />} spacing="4">
            {props.content?.acf.biography.map((element, index) => {
              return (
                <EditorialDetailsContent
                  key={`EditorialDetailsContent-${index}`}
                  onSave={value => {
                    const tempBio = [...content.acf.biography];
                    tempBio[index].paragraph = value ?? '';
                    const tempContent = Object.assign(content);

                    setNestedProperty(tempContent, propertyName, tempBio);
                    setContent(tempContent);
                    props.onContentChange({
                      name: propertyName,
                      type: 'object',
                      value: tempBio,
                    });
                  }}
                  label={`Paragraph ${index}`}
                  paragraph={element.paragraph}
                  loading={props.loading}
                />
              );
            })}
          </Stack>
        </CardBody>
      </Card>
    );
  };

  const getTagTitle = () => {
    return (
      <Box
        textTransform={'uppercase'}
        color={'#fff'}
        bg={'#3382ce'}
        padding={'0.5rem'}
        fontSize={'0.9rem'}
        fontWeight={'700'}
        lineHeight={'1rem'}
        borderRadius={'0.15rem'}
      >
        <strong>{props.title}</strong>
      </Box>
    );
  };

  return (
    <Flex
      className="QueryResultListWrapper"
      width={'30%'}
      height={'calc(100vh - 5rem)'}
      direction={'column'}
      gap={3}
      margin={5}
    >
      <Flex
        width={'auto'}
        className="QueryResultListTitle"
        direction={'row'}
        gap={1}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Flex>{getTagTitle()}</Flex>
        <Text
          className="QueryEditorTitle"
          textColor={'#003058'}
          display={'inline'}
          fontSize={'0.9rem'}
          fontWeight={'700'}
          lineHeight={'1rem'}
          padding={'0.5rem'}
          textTransform={'uppercase'}
        >
          <Flex gap={'0.25rem'}>
            <strong>SOURCE:</strong>
            <Image
              width={'1rem'}
              src={
                'https://upload.wikimedia.org/wikipedia/commons/0/09/Wordpress-Logo.svg'
              }
            />
          </Flex>
        </Text>
      </Flex>
      {content ? (
        getEditorialDetails()
      ) : (
        <Card>
          <CardBody>
            <Stack divider={<StackDivider />} spacing="4">
              {_.map(new Array(5), (x, index) => {
                return <Skeleton key={`Skeleton-${index}`} height={'2rem'} />;
              })}
            </Stack>
          </CardBody>
        </Card>
      )}
      <Flex gap={'2rem'} width={'100%'}></Flex>
    </Flex>
  );
};
