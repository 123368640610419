import { FunctionComponent, useState } from 'react';
import { Supercentenarian } from '../../../../../data/map-info';
import {
  Box,
  Flex,
  Heading,
  IconButton,
  Input,
  Select,
  Text,
} from '@chakra-ui/react';
import { MdCheck, MdClose, MdModeEdit } from 'react-icons/md';
import { countries } from '../../../../../common/Countries';
import { getPropertyValue } from '../../../../../helpers/data-helper';

interface HumanDetailsContentProps {
  onSave: (value: string | undefined) => void;
  item: Supercentenarian;
  property: string;
  label: string;
  inputType: string;
  loading?: boolean;
  isEditable?: boolean;
}

export const HumanDetailsContent: FunctionComponent<
  HumanDetailsContentProps
> = props => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [propertyValue, setPropertyValue] = useState<any>(
    getPropertyValue(props.item, props.property)
  );

  const getValues = (property: string, value: any) => {
    if (value === null || value === undefined || value === '') {
      return 'N/A';
    }
    if (property === 'acf.sc_validated') {
      return value ? 'Validated' : 'Not Validated';
    }
    if (props.inputType === 'date') {
      return !value
        ? ''
        : new Date(value).toLocaleDateString('en-GB', {
            timeZone: 'UTC',
          });
    }
    if (property === 'acf.personal_information.is_dead') {
      return !value ? 'Alive' : 'Deceased';
    }
    return propertyValue;
  };

  const getInput = (property: string, value: string | undefined) => {
    if (props.inputType === 'select') {
      return (
        <>
          {property === 'acf.sc_validated' ? (
            <Select
              defaultValue={value}
              onChange={value =>
                setPropertyValue(
                  value.currentTarget.value === 'true' ? true : false
                )
              }
            >
              <option value={'true'}>Validated</option>
              <option value={'false'}>Not Validated</option>
            </Select>
          ) : property === 'acf.personal_information.is_dead' ? (
            <Select
              defaultValue={value}
              onChange={value =>
                setPropertyValue(
                  value.currentTarget.value === 'true' ? true : false
                )
              }
            >
              <option value={'true'}>Deceased</option>
              <option value={'false'}>Alive</option>
            </Select>
          ) : property === 'acf.personal_information.sex.name' ? (
            <Select
              defaultValue={value}
              onChange={value => setPropertyValue(value.currentTarget.value)}
            >
              <option value={'Female'}>Female</option>
              <option value={'Male'}>Male</option>
            </Select>
          ) : property ===
            'acf.personal_information.birth_place.country.name' ? (
            <Select
              defaultValue={value}
              onChange={value => setPropertyValue(value.currentTarget.value)}
            >
              <option value={''}>N/A</option>
              {countries.map(item => {
                return (
                  <option key={`BirthPlace-${item.name}`} value={item.name}>
                    {item.name}
                  </option>
                );
              })}
            </Select>
          ) : property === 'acf.personal_information.residence.country.name' ? (
            <Select
              defaultValue={value}
              onChange={value => setPropertyValue(value.currentTarget.value)}
            >
              <option value={''}>N/A</option>
              {countries.map(item => {
                return (
                  <option
                    key={`ResidenceCountry-${item.name}`}
                    value={item.name}
                  >
                    {item.name}
                  </option>
                );
              })}
            </Select>
          ) : property ===
            'acf.personal_information.death_place.country.name' ? (
            <Select
              defaultValue={value}
              onChange={value => setPropertyValue(value.currentTarget.value)}
            >
              <option value={''}>N/A</option>
              {countries.map(item => {
                return (
                  <option key={`DeathPlace-${item.name}`} value={item.name}>
                    {item.name}
                  </option>
                );
              })}
            </Select>
          ) : (
            <></>
          )}
        </>
      );
    }

    return (
      <Input
        type={props.inputType}
        size={'sm'}
        onChange={event =>
          setPropertyValue(
            event?.currentTarget.value === ''
              ? undefined
              : event?.currentTarget.value
          )
        }
        defaultValue={
          props.inputType === 'date' && value !== null
            ? propertyValue?.substring(0, 10)
            : propertyValue
        }
      />
    );
  };

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      gap={5}
      overflow={'auto'}
    >
      <Heading size="xs" textTransform="uppercase" width={'6rem'}>
        {props.label}
      </Heading>
      {editMode ? (
        getInput(props.property, propertyValue)
      ) : (
        <Text pt="2" fontSize="sm">
          {getValues(props.property, propertyValue)}
        </Text>
      )}
      {editMode ? (
        <Flex gap={1}>
          <IconButton
            icon={<MdCheck />}
            size={'sm'}
            aria-label="accept"
            onClick={() => {
              setEditMode(false);
              props.onSave(propertyValue);
            }}
          />
          <IconButton
            icon={<MdClose />}
            size={'sm'}
            aria-label="cancel"
            onClick={() => {
              setEditMode(false);
              setPropertyValue(getPropertyValue(props.item, props.property));
            }}
          />
        </Flex>
      ) : (
        <IconButton
          icon={<MdModeEdit />}
          isDisabled={props.loading || props.isEditable === false}
          isLoading={props.loading}
          size={'sm'}
          aria-label="edit"
          onClick={() => setEditMode(true)}
        />
      )}
    </Box>
  );
};
