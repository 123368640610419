import {
  Stack,
  InputGroup,
  Input,
  Button,
  Flex,
  Checkbox,
} from '@chakra-ui/react';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import {
  MultipleSelector,
  OptionProps,
} from '../../../../common/components/MultipleSelector';
import {
  DateRangePicker,
  Range,
} from '../../../../common/components/DatePicker/DateRangePicker';
import { countryReference } from '../deckGl/data/countries-data';
import { SearchQuery, queryBuilder } from '../../../../helpers/query-builder';

export interface SearchFormProps {
  isLoading?: boolean;
  search: (query: SearchQuery) => void;
}

export const SearchForm: FunctionComponent<SearchFormProps> = props => {
  const nameInput = useRef<HTMLInputElement>(null);
  const [isLiving, setIsLiving] = useState<boolean>();
  const [birthDateRange, setBirthDateRange] = useState<Range>();
  const [deathDateRange, setDeathDateRange] = useState<Range>();
  const [birthPlaces, setBirthPlaces] = useState<string[]>();
  const [deathPlaces, setDeathPlaces] = useState<string[]>();
  const [currentResidencePlaces, setCurrentResidencePlace] =
    useState<string[]>();
  const [gender, setGender] = useState<string[]>();
  const [age, setAge] = useState<string[]>();
  const [status, setStatus] = useState<string[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(props.isLoading ?? false);
  }, [props.isLoading]);

  const buildQuery = () => {
    const query = queryBuilder
      .clear()
      .withName(nameInput?.current?.value)
      .withBirthDateRange(birthDateRange)
      .withDeathDateRange(deathDateRange)
      .withBirthPlace(birthPlaces)
      .withDeathPlace(deathPlaces)
      .withStatus(status)
      .withCurrentResidencePlace(currentResidencePlaces)
      .isLiving(isLiving ?? false)
      .withGender(gender)
      .widthAge(age)
      .build();
    return query;
  };

  const handelSearch = () => {
    if (props.search) {
      const query = buildQuery();
      props.search(query);
    }
  };

  useEffect(() => {
    if (
      !gender &&
      !status &&
      !deathDateRange &&
      !birthDateRange &&
      !birthPlaces &&
      !deathPlaces &&
      !age &&
      !currentResidencePlaces
    ) {
      return;
    }
    handelSearch();
  }, [
    gender,
    birthDateRange,
    deathPlaces,
    deathDateRange,
    status,
    birthPlaces,
    age,
    currentResidencePlaces,
  ]);
  return (
    <Stack marginBottom={16} background="#FFFFFF">
      <Flex direction={'row'} width={'100%'} gap={2}>
        <InputGroup size="lg" width="80%">
          <Input
            ref={nameInput}
            placeholder="Enter Name"
            size="lg"
            onKeyDown={e => {
              if (e.code === 'Enter') {
                handelSearch();
              }
            }}
          />
        </InputGroup>
        <Button
          isLoading={isLoading}
          size="lg"
          colorScheme="teal"
          onClick={() => {
            handelSearch();
          }}
        >
          Search
        </Button>
      </Flex>
      <Flex direction={'column'} gap={'6rem'}>
        <Flex gap={10}>
          <DateRangePicker
            style={{ width: '290px' }}
            title="Birth date"
            date={
              birthDateRange ?? {
                startDate: new Date(1750, 0),
                endDate: new Date(1750, 0),
              }
            }
            onChange={dateRange => {
              setBirthDateRange(dateRange);
            }}
          ></DateRangePicker>
          <DateRangePicker
            style={{ width: '290px' }}
            isDeath={true}
            title="Death date"
            date={
              deathDateRange ?? {
                startDate: new Date(1750, 0),
                endDate: new Date(1750, 0),
              }
            }
            onChange={dateRange => {
              setDeathDateRange(dateRange);
            }}
          ></DateRangePicker>
          <MultipleSelector
            style={{ width: '200px' }}
            title="Birth place"
            onSelect={selectedValues => {
              const items = selectedValues.map(item => item.value);
              setBirthPlaces(items);
            }}
            options={countryReference.map(x => {
              return {
                id: x.numeric,
                text: x.country,
                value: x.country,
                isSelected: birthPlaces?.includes(x.country),
              } as OptionProps;
            })}
          />
          <MultipleSelector
            style={{ width: '200px' }}
            title="Death place"
            onSelect={selectedValues => {
              const items = selectedValues.map(item => item.value);
              setDeathPlaces(items);
            }}
            options={countryReference.map(x => {
              return {
                id: x.numeric,
                text: x.country,
                value: x.country,
                isSelected: deathPlaces?.includes(x.country),
              } as OptionProps;
            })}
          />
        </Flex>
        <Flex gap={5} alignItems={'center'}>
          <MultipleSelector
            style={{ width: '200px' }}
            title="Current residence"
            onSelect={selectedValues => {
              const items = selectedValues.map(item => item.value);
              setCurrentResidencePlace(items);
            }}
            options={countryReference.map(x => {
              return {
                id: x.numeric,
                text: x.country,
                value: x.country,
                isSelected: currentResidencePlaces?.includes(x.country),
              } as OptionProps;
            })}
          />
          <MultipleSelector
            style={{ width: '200px' }}
            title="Gender"
            onSelect={selectedValues => {
              const items = selectedValues.map(item => item.value);
              setGender(items);
            }}
            options={[
              {
                id: 0,
                text: 'Female',
                value: 'Female',
                isSelected: gender?.includes('Female'),
              },
              {
                id: 1,
                text: 'Male',
                value: 'Male',
                isSelected: gender?.includes('Male'),
              },
            ]}
          />
          <MultipleSelector
            style={{ width: '250px' }}
            title="Age"
            onSelect={selectedValues => {
              const items = selectedValues.map(item => item.value);
              setAge(items);
            }}
            options={[
              {
                id: 0,
                text: 'Between 100 and 110',
                value: '[100,110]',
                isSelected: age?.includes('[100,110]'),
              },
              {
                id: 1,
                text: 'Between 111 and 120',
                value: '[111,120]',
                isSelected: age?.includes('[111,120]'),
              },
              {
                id: 2,
                text: 'Between 121 and 130',
                value: '[121,130]',
                isSelected: age?.includes('[121,130]'),
              },
              {
                id: 3,
                text: 'Greater Than 130',
                value: '[130,null]',
                isSelected: age?.includes('[130,null]'),
              },
            ]}
          />
          <MultipleSelector
            style={{ width: '200px' }}
            title="Status"
            onSelect={selectedValues => {
              const items = selectedValues.map(item => item.value);
              setStatus(items);
            }}
            options={[
              {
                id: 0,
                text: 'Validated',
                value: '1',
                isSelected: status?.includes('1'),
              },
              {
                id: 1,
                text: 'Not Validated',
                value: '0',
                isSelected: status?.includes('0'),
              },
            ]}
          />
          <Checkbox
            isChecked={isLiving}
            onChange={e => {
              setIsLiving(e.currentTarget.checked);
            }}
            minW={'6rem'}
          >
            Is Living
          </Checkbox>
        </Flex>
      </Flex>
    </Stack>
  );
};
