import { FunctionComponent } from 'react';
import {
  Box,
  Card,
  CardBody,
  Flex,
  Heading,
  List,
  ListItem,
  Skeleton,
  Stack,
  StackDivider,
  Text,
  Image,
} from '@chakra-ui/react';
import './NewTaxonomyCard.scss';
import { SupercentenarianTaxonomy } from '../../../../../data/taxonomy-data';
import lqIcon from '../../../../../assets/lq-icon.svg';
import _ from 'lodash';

interface TaxonomySectionProps {
  title: string;
  taxonomy?: SupercentenarianTaxonomy;
  loading?: boolean;
}

export const NewTaxonomyCard: FunctionComponent<
  TaxonomySectionProps
> = props => {
  const formatValue = (variable: string, value: any) => {
    if (typeof value === 'object' && !Array.isArray(value)) {
      if (variable === 'standing' || variable === 'walking') {
        const entries = Object.entries(value).map(
          ([key, val]) => `${capitalizeEachWord(key)}: ${val}`
        );
        return `${entries.join(', ')}`;
      }
      if ('years' in value && 'days' in value) {
        return `${value.years} years, ${value.days} days`;
      }
      return JSON.stringify(value);
    } else if (Array.isArray(value)) {
      return value.join(', ');
    } else {
      return String(value);
    }
  };

  const capitalizeEachWord = (str: string) => {
    let newStr = str.replace(/_/g, ' ');
    return newStr.replace(/\b\w/g, char => char.toUpperCase());
  };

  const getHumanDetails = () => {
    if (!props.taxonomy) {
      return;
    }
    return (
      <Card>
        <CardBody>
          <Stack divider={<StackDivider />} spacing="4">
            {Object.entries(props.taxonomy || {}).map(
              ([category, variables]) => (
                <div key={category}>
                  <Heading
                    size={'xs'}
                    textTransform={'uppercase'}
                    mb={'0.5rem'}
                  >
                    {capitalizeEachWord(category)}
                  </Heading>
                  <List>
                    {Object.entries(variables).map(([variable, value]) => (
                      <ListItem key={variable} mb={'0.25rem'}>
                        <Text size={'sm'}>{`${capitalizeEachWord(
                          variable
                        )}: ${formatValue(variable, value)}`}</Text>
                      </ListItem>
                    ))}
                  </List>
                </div>
              )
            )}
          </Stack>
        </CardBody>
      </Card>
    );
  };

  const getTagTitle = () => {
    return (
      <Box
        textTransform={'uppercase'}
        color={'#fff'}
        bg={'#3382ce'}
        padding={'0.5rem'}
        fontSize={'0.9rem'}
        fontWeight={'700'}
        lineHeight={'1rem'}
        borderRadius={'0.15rem'}
      >
        <strong>{props.title}</strong>
      </Box>
    );
  };

  return (
    <Flex
      className="QueryResultListWrapper"
      width={'30%'}
      height={'calc(100vh - 5rem)'}
      direction={'column'}
      gap={3}
      margin={5}
    >
      <Flex
        width={'auto'}
        className="QueryResultListTitle"
        direction={'row'}
        gap={1}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Flex>{getTagTitle()}</Flex>
        <Text
          className="QueryEditorTitle"
          textColor={'#003058'}
          display={'inline'}
          fontSize={'0.9rem'}
          fontWeight={'700'}
          lineHeight={'1rem'}
          padding={'0.5rem'}
          textTransform={'uppercase'}
        >
          <Flex gap={'0.25rem'}>
            <strong>SOURCE:</strong>
            <Image width={'1rem'} src={lqIcon} />
          </Flex>
        </Text>
      </Flex>
      {props.taxonomy ? (
        getHumanDetails()
      ) : (
        <Card>
          <CardBody>
            {props.loading ? (
              <Stack divider={<StackDivider />} spacing="4">
                {_.map(new Array(5), (x, index) => {
                  return <Skeleton key={`Skeleton-${index}`} height={'2rem'} />;
                })}
              </Stack>
            ) : (
              <div>No data at this time</div>
            )}
          </CardBody>
        </Card>
      )}
      <Flex gap={'2rem'} width={'100%'}></Flex>
    </Flex>
  );
};
