import { Outlet } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';

import { SideBar } from './components/SideBar';

export const MainLayout = () => {
  return (
    <Flex className="MainLayout" width={'100%'} height={'100vh'}>
      <SideBar />
      <Box className="MainLayoutContentWrapper" width={'100%'}>
        <Outlet />
      </Box>
    </Flex>
  );
};
