import {
  Box,
  Flex,
  Text,
  Image,
  Card,
  CardBody,
  Stack,
  StackDivider,
  Skeleton,
} from '@chakra-ui/react';
import { FunctionComponent, useEffect, useState } from 'react';
import { PropertyToEdit, Supercentenarian } from '../../../../../data/map-info';
import lqIcon from '../../../../../assets/lq-icon.svg';
import { allowedHumanFields } from '../../../data/allowed-fields';
import { HumanDetailsContent } from '../HumanDetailsContent/HumanDetailsContent';
import _ from 'lodash';
import { setNestedProperty } from '../../../../../helpers/data-helper';

interface DetailCardProps {
  title: string;
  source: string;
  content?: Supercentenarian;
  loading?: boolean;
  onContentChange: (propertyToEdit: PropertyToEdit) => void;
}

export const HumanDetailCard: FunctionComponent<DetailCardProps> = props => {
  const [content, setContent] = useState<Supercentenarian>();
  const [isDead, setIsDead] = useState<boolean>();
  useEffect(() => {
    setContent(props.content);
    setIsDead(props.content?.acf.personal_information.is_dead ?? false);
  }, [props]);

  const getHumanDetails = () => {
    if (!content) {
      return;
    }
    return (
      <Card>
        <CardBody>
          <Stack divider={<StackDivider />} spacing="4">
            {allowedHumanFields.map((field, i) => {
              if (field.canHideIfDataIsNull) {
                return null;
              }
              const isEditable = (): boolean => {
                if (field.dependsOnLivingStatus === true && isDead === false) {
                  return false;
                }
                return true;
              };

              return (
                <HumanDetailsContent
                  key={`HumanDetailsContent-${i}`}
                  onSave={value => {
                    const tempContent = Object.assign(content);
                    setNestedProperty(tempContent, field.name, value);
                    setContent(tempContent);
                    setIsDead(
                      tempContent?.acf.personal_information.is_dead ?? false
                    );
                    props.onContentChange({
                      name: field.name,
                      type: field.type,
                      value: value,
                    });
                  }}
                  item={content}
                  label={field.label}
                  inputType={field.type}
                  property={field.name}
                  loading={props.loading}
                  isEditable={isEditable()}
                />
              );
            })}
          </Stack>
        </CardBody>
      </Card>
    );
  };

  const getTagTitle = () => {
    return (
      <Box
        textTransform={'uppercase'}
        color={'#fff'}
        bg={'#3382ce'}
        padding={'0.5rem'}
        fontSize={'0.9rem'}
        fontWeight={'700'}
        lineHeight={'1rem'}
        borderRadius={'0.15rem'}
      >
        <strong>{props.title}</strong>
      </Box>
    );
  };

  return (
    <Flex
      className="QueryResultListWrapper"
      width={'30%'}
      height={'calc(100vh - 5rem)'}
      direction={'column'}
      gap={3}
      margin={5}
    >
      <Flex
        width={'auto'}
        className="QueryResultListTitle"
        direction={'row'}
        gap={1}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Flex>{getTagTitle()}</Flex>
        <Text
          className="QueryEditorTitle"
          textColor={'#003058'}
          display={'inline'}
          fontSize={'0.9rem'}
          fontWeight={'700'}
          lineHeight={'1rem'}
          padding={'0.5rem'}
          textTransform={'uppercase'}
        >
          <Flex gap={'0.25rem'}>
            <strong>SOURCE:</strong>
            <Image width={'1rem'} src={lqIcon} />
          </Flex>
        </Text>
      </Flex>
      {content ? (
        getHumanDetails()
      ) : (
        <Card>
          <CardBody>
            <Stack divider={<StackDivider />} spacing="4">
              {_.map(new Array(5), (_x, index) => {
                return <Skeleton key={`Skeleton-${index}`} height={'2rem'} />;
              })}
            </Stack>
          </CardBody>
        </Card>
      )}
      <Flex gap={'2rem'} width={'100%'}></Flex>
    </Flex>
  );
};
