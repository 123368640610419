import {
  Box,
  Flex,
  Heading,
  IconButton,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { FunctionComponent, useState } from 'react';
import { MdCheck, MdClose, MdModeEdit } from 'react-icons/md';

interface EditorialDetailsContentProps {
  onSave: (value: string | undefined) => void;
  paragraph: string;
  label: string;
  loading?: boolean;
  isEditable?: boolean;
}

export const EditorialDetailsContent: FunctionComponent<
  EditorialDetailsContentProps
> = props => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [paragraphValue, setParagraphValue] = useState<string | undefined>(
    props.paragraph
  );

  const getInput = () => {
    return (
      <Textarea
        size={'sm'}
        onChange={event =>
          setParagraphValue(
            event?.currentTarget.value === ''
              ? undefined
              : event?.currentTarget.value
          )
        }
        defaultValue={paragraphValue}
      />
    );
  };

  return (
    <Box
      display={'flex'}
      flexDir={'column'}
      justifyContent={'space-between'}
      gap={5}
      overflow={'auto'}
    >
      <Flex
        gap={5}
        justifyContent={'space-between'}
        width={'100%'}
        alignItems={'flex-start'}
      >
        <Heading size="xs" textTransform="uppercase" width={'7rem'}>
          {props.label}
        </Heading>
        {editMode ? (
          <Flex gap={1}>
            <IconButton
              icon={<MdCheck />}
              size={'sm'}
              aria-label="accept"
              onClick={() => {
                setEditMode(false);
                props.onSave(paragraphValue);
              }}
            />
            <IconButton
              icon={<MdClose />}
              size={'sm'}
              aria-label="cancel"
              onClick={() => {
                setEditMode(false);
                setParagraphValue(props.paragraph);
              }}
            />
          </Flex>
        ) : (
          <IconButton
            icon={<MdModeEdit />}
            isDisabled={props.loading || props.isEditable === false}
            isLoading={props.loading}
            size={'sm'}
            aria-label="edit"
            onClick={() => setEditMode(true)}
          />
        )}
      </Flex>

      {editMode ? (
        getInput()
      ) : (
        <Text pt="2" fontSize="sm">
          {paragraphValue && paragraphValue !== '' ? paragraphValue : 'N/A'}
        </Text>
      )}
    </Box>
  );
};
