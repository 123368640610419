import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';

import { useState } from 'react';
import { withAuthProtection } from '../../services/protect-route-element';
import { queryService } from '../../services/query-service';
import { MapInfo } from '../../data/map-info';
import { SearchForm } from './components/SearchForm/SearchForm';
import { SearchResultList } from '../../common/components/SearchResultList/SearchResultList';
import { SearchQuery } from '../../helpers/query-builder';

const Page = () => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [queryData, setQueryData] = useState<MapInfo>();

  const performSearch = (query: SearchQuery) => {
    setIsFetching(true);
    if (!query) {
      setIsFetching(false);
      setQueryData({ content: [], count: 0 });
      return;
    }

    queryService
      .getSearchQuery(query)
      .then(response => {
        setQueryData(response);
      })
      .catch(error => {
        setIsFetching(false);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  const getSearchResults = () => {
    return (
      <Flex width={'100%'} className="QueryViewWrapper">
        <Tabs className="QueryViewTabs" width={'100%'} variant="enclosed">
          <TabList className="QueryViewTabList" width={'100%'}>
            <Tab className="QueryViewTab">List</Tab>
          </TabList>
          <TabPanels className="QueryViewTabPanels">
            <TabPanel className="QueryViewTabPanel">
              <SearchResultList
                showDetails={true}
                content={queryData?.content ?? []}
                count={queryData?.count ?? 0}
                isLoaded={!isFetching}
                panelInfo="calc(100vh - 31rem)"
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    );
  };

  return (
    <Flex
      className="QueryPageWrapper"
      direction={'column'}
      padding={2}
      bgColor={'#ffffff'}
      height={'100%'}
    >
      <Flex className="QueryPageEditorWrapper" direction={'column'} gap={2}>
        <Tabs variant="soft-rounded" colorScheme="green">
          <TabList>
            <Tab>FORM</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <SearchForm isLoading={isFetching} search={performSearch} />
            </TabPanel>
          </TabPanels>
        </Tabs>
        {getSearchResults()}
      </Flex>
    </Flex>
  );
};

export const SearchPage = withAuthProtection(Page);
