export const configuration = {
  cedApiKey: "09c0a237638953fffc5b6a5277b00376e4375ff7dc4a6beb3252b0564e32edbfee80163be194e81d0489a765e056a04bd979",
  environment: "production",
  version: "1.0.2",
  api: {
    hostingUrl: "https://backend-api-n7yketlr5q-uc.a.run.app",
  },
  backend: {
    url: "https://lq-data-platform-api-prod-dee07c0bb7e0.herokuapp.com/",
  },
  userRegistry: {
    domain: "dev-lhgvjxwvgnjbvugl.us.auth0.com",
    audience: "https://dev-lhgvjxwvgnjbvugl.us.auth0.com/api/v2/",
    clientId: "eUGJYjX6HNtJYpwxDLwANTJyICgWEhoJ",
  },
};