import { AxiosRequestConfig } from "axios";
import { authentication } from "../authentication";

import { ApiClient } from "./api-client";

export class BearerTokenApiClient extends ApiClient {
  private _tokenOrTokenResolver: string | (() => Promise<string>) = async () =>
    authentication.getAccessToken() || "";

  public withBearerToken(bearerToken: string) {
    this._tokenOrTokenResolver = bearerToken;
    return this;
  }

  protected onError(error: Error) {
    const e = error as any;
    if (
      e.isAxiosError &&
      (e.response?.status === 401 || e.response?.status === 403)
    ) {
      if (authentication.isAuthenticated()) {
        authentication.logout();
        return;
      }
    }
  }

  protected async _getDefaultRequestConfig(): Promise<AxiosRequestConfig> {
    const baseConfig = (await super._getDefaultRequestConfig()) || {};
    const token = await this._getToken();
    return {
      ...baseConfig,
      headers: {
        authorizationToken: `Bearer ${token}`,
      },
    };
  }

  private async _getToken(): Promise<string> {
    if (typeof this._tokenOrTokenResolver === "string") {
      return this._tokenOrTokenResolver;
    }
    return this._tokenOrTokenResolver();
  }
}
