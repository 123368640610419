import { LoginData } from "../data/login-data";

enum localStorageKey {
  LoginData = "loginData",
}

class LocalStorageService {
  public clearLoginData() {
    localStorage.removeItem(localStorageKey.LoginData);
  }

  public setLoginData(loginData: LoginData) {
    localStorage.setItem(localStorageKey.LoginData, JSON.stringify(loginData));
  }

  public getLoginData(): LoginData | null {
    const data = localStorage.getItem(localStorageKey.LoginData);
    return this.parseData<LoginData>(data);
  }

  private parseData<T>(data: any): T {
    return data !== null ? JSON.parse(data) : null;
  }
}

export const localStorageService = new LocalStorageService();
