import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { authentication } from "../../services/authentication";
import { LoginPage } from "../LoginPage/LoginPage";
import "./LoginCallbackPage.scss";

const Page = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const completeLogin = async () => {
      try {
        await authentication.handleRedirectCallback();
        navigate("/home");
      } catch (err) {
        console.error(err);
      }
    };
    completeLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <LoginPage />;
};

export const LoginCallbackPage = Page;
